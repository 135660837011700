import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { IframeMain } from "../styles/iframe.styles"
import { SectionTitle } from "../elements/section-title"
import { HexagonTitle } from "../elements/hexagon-title"
import { HexagonTitleMain } from "../styles/common.styles"

const VideoIframe = (
    {
        data: {
          wpgraphql: {
            page: {
              title,
              videoiframeacf
            }
          },
        }
      }
) => {

    return (

  //     <Layout>
            
  //     <SEO title={title} />

  //     <IframeMain>
  //         <div class="iframe-container">
  //             <iframe class="vimeo-iframe" src="https://player.vimeo.com/video/814919417?h=d90a64ea3b" width="740" height="460" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
  //         </div>
  //     </IframeMain>


  // </Layout>

        <Layout>
            
            <SEO title={title} />

            <IframeMain>
                <div class="iframe-container vif-pd">
  
                    <div>
                        <SectionTitle variation="title-container" text="Brevan Howard Risk Management" responsive={[{ breakpoint: 767, charLimit: 6 }]} />
                        {/* {videoiframeacf.iframe.title} */}
                        <HexagonTitleMain style={{textAlign: 'center', marginBottom: "2rem", marginTop: "20px" }}>
                          <span className="hexagon-wrap"></span>
                          <span className="hexagon-text" dangerouslySetInnerHTML={{__html: videoiframeacf.iframe.author}}></span>
                        </HexagonTitleMain>
                    </div>

                    <iframe src={videoiframeacf.iframe.link.url} width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                </div>
                
            </IframeMain>

        </Layout>
    )
}

export default VideoIframe;

export const homeTemplateQuery = graphql`
  query VideoIframeQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {   
        title 
        videoiframeacf {
            fieldGroupName
            iframe {
              link {
                target
                title
                url
              }
              author
              fieldGroupName
              title
            }
          }
      }
    }
  }
`


// // export const homeTemplateQuery = graphql`
// //   query VideoIframeQuery($id: ID!) {
// //     wpgraphql {
// //       page(id: $id) {
// //         title
// //         videoiframeacf {
// //             title
// //             author
// //             link
// //         }
// //       }
// //     }
// //   }
// // `


// import React from "react"

/* import React, { useState } from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { IframeMain } from "../styles/iframe.styles"
import { graphql } from "gatsby"
import { SectionTitle } from "../elements/section-title"
import { HexagonTitle } from "../elements/hexagon-title"
import { HexagonTitleMain } from "../styles/common.styles"


const data = [
    {
      videos: [
        {
            vimeoCode: "814919417",
          title: "WITH ALEX ASSOULINE",
        },
        // {
        //     vimeoCode: "832176817",
        //   title: "BH Digital – Digital Asset Ecosystem",
        // },
      
      ],
      heading: "Brevan Howard Risk Management",
    },

  
  ];



 function VideoIframe() {
      return (
        <Layout style="padding-top 20px !important" >
            
            <SEO title="New Video Design" />

            <IframeMain>
                
            <div   class="iframe-container-box">
            
            {data.map((section) => (

                    <div   class="iframe-container">
                    <div>
                    <SectionTitle variation="title-container" text={section.heading} responsive={[{ breakpoint: 767, charLimit: 6 }]} />
            
                    </div>

                    {section.videos.map((video) => (
                        <div>
                    <HexagonTitleMain style={{textAlign: 'center', marginBottom: "2rem", marginTop: "20px" }}>
                    <span className="hexagon-wrap">
                    </span>
                    <span className="hexagon-text">{video.title}</span>
                    </HexagonTitleMain>

                    <iframe class="vimeo-iframe" src={'https://player.vimeo.com/video/' +video.vimeoCode+'?h=d90a64ea3b'}  width="740" height="460" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    

             ))}

                </div>  


            ))}
            </div>
                        </IframeMain>

            </Layout>

            
            
    );
   }

export default VideoIframe; */